<template>
  <div>
    <!-- <HeadCards /> -->
    <full-table
      :other-filter-props="{
        add: true,
        search: false,
        gender: false,
        role: false,
      }"
      :items="GET_ONE_BRANCHES_GROUP.branches_info"
      :fields="fields"
      :edit-btn="false"
      :delete-btn="false"
      :first-params="params"
      :pagination="false"
      :empty-text="$t('general.no_data_to_view')"
      @refresh="FETCH_ONE_BRANCHES_GROUP"
      @row-clicked="(data) => $router.push(`/branches-list/${data.id}/`)"
      @detail-button-clicked="(id) => $router.push(`/branches-list/${id}/`)"
      @delete-button-clicked="modalDeleteUser"
      @add-button-clicked="addUser"
    />
    <add-users-to-tag-modal
      :def-selected="defSelected"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
// import HeadCards from '@/components/HeadCards.vue'
import FullTable from '@/components/FullTable.vue'
import AddUsersToTagModal from './modals/AddUsersToTagModal.vue'

export default {
  name: 'User',
  components: {
    FullTable,
    AddUsersToTagModal,
    // HeadCards,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      isEdit: false,
      defSelected: [],
      params: {
        // page_size: 10,
        // page: 1,
        id: this.$route.params.id,
        // ordering: null,
        // search: null,
      },
      fields: [
        {
          label: 'Название филиала',
          key: 'title',
        },
        {
          label: 'Лимит пользователей (Ед.)',
          key: 'capacity',
        },
        // {
        //   label: 'Лимит памяти (GB)',
        //   key: 'storage',
        // },
        {
          label: 'Дата создания',
          key: 'access_start_date',
        },
        {
          label: 'Дата окончения',
          key: 'access_end_date',
        },
        {
          label: 'Статус',
          key: 'is_active',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('branches', ['GET_ONE_BRANCHES_GROUP']),
  },
  mounted() {
    this.FETCH_ONE_BRANCHES_GROUP(this.params)
      .then(() => {
        this.updateBreadcrumb()
      })
  },
  methods: {
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    ...mapActions('branches', ['FETCH_BRANCHES_FOR_GROUP', 'FETCH_ONE_BRANCHES_GROUP', 'DELETE_USER_IN_GROUP']),
    addUser() {
      this.defSelected = this.GET_ONE_BRANCHES_GROUP.branches_info.map(e => e.id)
      this.$nextTick(() => {
        this.$bvModal.show('UsersAddToTagModal')
      })
    },
    refresh() {
      this.FETCH_ONE_BRANCHES_GROUP(this.params)
    },
    updateBreadcrumb() {
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = `${this.GET_ONE_BRANCHES_GROUP.title}`
      this.UPDATE_BREADCRUMB([first, second])
    },
    modalDeleteUser(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_USER_IN_GROUP(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.params.page = 1
              this.FETCH_ONE_BRANCHES_GROUP(this.params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
  },
}
</script>
