<template>
  <div>
    <filters
      v-bind="otherFilterProps"
      :class="otherFilterProps.search ? '' : 'mb-1'"
      @import="$emit('import')"
      @onExportExcel="(data) => $emit('export', data)"
      @filtered-items="filter"
      @add-button-clicked="$emit('add-button-clicked')"
    />
    <b-card :no-body="noBody">
      <b-table
        v-bind="otherTableProps"
        :fields="fields"
        :items="items"
        style-class="slots_text_align vgt-table bordered"
        show-empty
        :empty-text="emptyText"
        hover
        responsive
        no-local-sorting
        @sort-changed="sorting"
        @row-clicked="(data) => $emit('row-clicked', data)"
      >
        <template #head()="data">
          {{ $t(data.label) }}
        </template>
        <template
          v-if="editBtn || deleteBtn || detailBtn"
          #cell(actions)="data"
        >
          <div class="d-flex align-items-center no-wrap">
            <b-button
              v-if="detailBtn"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$emit('detail-button-clicked', data.item.id, data.item)"
            >
              <feather-icon
                size="22"
                icon="ChevronsRightIcon"
              />
            </b-button>
            <b-button
              v-if="editBtn"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$emit('edit-button-clicked', data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="22"
              />
            </b-button>
            <b-button
              v-if="deleteBtn"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="$emit('delete-button-clicked', data.item.id, params)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </template>
        <template #cell(roles)="data">
          <span
            v-for="item in data.value"
            :key="item.id"
          >
            <b-badge
              variant="success"
              class="mr-50"
            >
              {{ item.title[$i18n.locale] }}
              <!-- {{item}} -->
            </b-badge>
          </span>
        </template>
        <template #cell(is_active)="data">
          <b-form-checkbox
            :checked="data.value"
            name="check-button"
            switch
            inline
            disabled
          />
        </template>

        <template #cell(class)="data">
          <span
            v-for="(item, index) in data.value"
            :key="index"
          >
            {{ data.value }}
          </span>
        </template>

        <template #cell(sciences)="data">
          <span
            v-for="item in data.value"
            :key="item.id"
          >
            <b-badge
              variant="primary"
              class="mr-50"
            >
              {{ item.title }}
            </b-badge>
          </span>
        </template>

        <template #cell(phones)="{ item }">
          <span
            v-for="(phone, index) in item.contact.phones"
            :key="index"
          >
            <b-badge
              variant="danger"
              class="mr-50"
            > +{{ phone }} </b-badge>
          </span>
        </template>
      </b-table>

      <!-- pagination -->
      <!-- slot-scope="props" -->
      <template v-if="pagination">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">{{ $t("general.show_from") }}</span>
            <b-form-select
              v-model="params.page_size"
              :options="pageOptions"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="text-nowrap">{{ $t("general.show_to") }}</span>
            <!-- из {{ props.total }} -->
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="rows"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BPagination,
  BFormSelect,
  BButton,
  BTable,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import filters from '@/components/filters.vue'

export default {
  name: 'User',
  components: {
    BTable,
    BCard,
    BPagination,
    BFormSelect,
    BButton,
    BBadge,
    BFormCheckbox,
    filters,
  },
  directives: {
    Ripple,
  },
  props: {
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    emptyText: {
      type: String,
      required: true,
    },
    otherTableProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    otherFilterProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    rows: {
      type: Number,
      required: false,
      default: 1,
    },
    editBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    deleteBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    detailBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    noBody: {
      type: Boolean,
      required: false,
      default: false,
    },
    pagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    firstParams: {
      type: Object,
      required: false,
      default: () => ({
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      }),
    },
    defaultParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      pageOptions: [3, 5, 10],
      params: this.firstParams,
    }
  },
  mounted() {
    this.$emit('refresh', this.params)
  },
  methods: {
    modalDeleteUser(id) {
      this.$emit('on-delete-item', id)
    },
    sorting(e) {
      if (e.sortBy === 'contact.full_name') {
        if (e.sortDesc) {
          this.params.ordering = 'contact__last_name'
          this.$emit('refresh', this.params)
        } else {
          this.params.ordering = '-contact__last_name'
          this.$emit('refresh', this.params)
        }
      } else if (e.sortBy === 'contact.id') {
        if (e.sortDesc) {
          this.params.ordering = '-id'
          this.$emit('refresh', this.params)
        } else {
          this.params.ordering = 'id'
          this.$emit('refresh', this.params)
        }
      } else {
        this.params.ordering = null
        this.$emit('refresh', this.params)
      }
    },
    // for pagination and filters
    changePerPage(size) {
      this.params.page_size = size
      this.params.page = 1
      this.$emit('refresh', this.params)
    },
    changePage(value) {
      this.params.page = value
      this.$emit('refresh', this.params)
    },
    filter(filteredData) {
      this.params.page = 1
      this.params = { ...this.params, ...filteredData }
      this.$emit('refresh', this.params)
    },
  },
}
</script>
